@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Anton+SC&family=Play:wght@400;700&display=swap');

.anton {
  font-family: 'Anton SC', sans-serif;
}

.play {
  font-family: 'Play', sans-serif;
}

